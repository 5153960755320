import React from 'react';
import { List as ChakraList } from '@chakra-ui/react';
import ListContext from './ListContext';

type ListProps = {
  /** If provided, will render as a hoverable, focusable, "button" */
  actionable?: boolean;
  children: React.ReactNode;
};

// eslint-disable-next-line import/prefer-default-export
export const List = <T extends {}>({
  children,
  actionable,
  ...rest
}: ListProps & T) => (
  <ChakraList
    background="white"
    rounded={{
      sm: 'lg',
    }}
    overflow="hidden"
    borderWidth="1px"
    {...rest}
  >
    <ListContext.Provider value={{ actionable: !!actionable }}>
      {children}
    </ListContext.Provider>
  </ChakraList>
);
