import React from 'react';
import {
  useGetActivePriorityEventsSubscription,
  useSetSelectedFlightPlanMutation,
} from 'codegen-artifacts/generated/mcng/user';
import openRequestedWindow from '@/src/utils/openRequestedWindow';
import PriorityEvents from '@/src/components/PriorityEvents';
import { useSession } from 'next-auth/client';
import { Skeleton } from '@chakra-ui/react';
import parsePriorityEvents from './parsePriorityEvents';

type PriorityEventsViewProps = {
  operatingEnvironmentDomains?: string[];
};

const PriorityEventsView = ({
  operatingEnvironmentDomains,
}: PriorityEventsViewProps) => {
  const [session] = useSession();

  const { data: importantPriorityEventsData, loading } =
    useGetActivePriorityEventsSubscription({
      variables: { operatingEnvironmentDomains },
    });

  const [setSelectedFlightPlan] = useSetSelectedFlightPlanMutation({
    onCompleted: () => {
      openRequestedWindow(`${window.location.origin}/vehicle`, 'vehicle');
    },
  });

  const importantPriorityEvents =
    importantPriorityEventsData &&
    importantPriorityEventsData.vehiclePriorityEvents.length
      ? parsePriorityEvents(importantPriorityEventsData.vehiclePriorityEvents)
      : [];

  return (
    <Skeleton isLoaded={!loading}>
      <PriorityEvents
        data-testid="priority-events"
        data={importantPriorityEvents}
        onClick={(flightPlanId) => {
          setSelectedFlightPlan({
            variables: {
              auth0UserId: session.user?.id,
              flightPlanId,
            },
          });
        }}
      />
    </Skeleton>
  );
};

export default PriorityEventsView;
