import React from 'react';
import { Flex, Box, Text, Stack, BoxProps, FlexProps } from '@chakra-ui/react';
import {
  Card,
  CardContent,
} from 'ui/mcng/@bit/matternet.shared.components.card';
import { motion } from 'framer-motion';
import { PriorityEventCardProps, PriorityEventCardCommonProps } from './types';
import DynamicBatteryIcon from '../shared/DynamicBatteryIcon';
import FlightStateTag from '../shared/FlightStateTag';
import { PESeverities } from './constants';
import { HazardousIcon, MajorIcon, MinorIcon } from './PriorityEventHeaderIcon';

const MotionBox = motion<BoxProps>(Box);
export const MotionFlex = motion<FlexProps>(Flex);

const PriorityEventCardHeading = ({
  vehicleName,
  severity,
}: {
  vehicleName: string;
  severity: PESeverities | '';
}) => {
  const severitySet: {
    backgroundColor: 'default' | 'red.100' | 'yellow.100' | 'blue.100';
    icon: React.ReactElement | null;
  } = {
    backgroundColor: 'default',
    icon: null,
  };

  if (severity === 'HAZARDOUS') {
    severitySet.backgroundColor = 'red.100';
    severitySet.icon = <HazardousIcon />;
  }

  if (severity === 'MAJOR') {
    severitySet.backgroundColor = 'yellow.100';
    severitySet.icon = <MajorIcon />;
  }

  if (severity === 'MINOR') {
    severitySet.backgroundColor = 'blue.100';
    severitySet.icon = <MinorIcon />;
  }

  return (
    <Flex
      backgroundColor={severitySet.backgroundColor}
      mx={-4}
      my={-5}
      px={4}
      py={5}
      justifyContent="space-between"
      alignItems="center"
      height="52px"
    >
      <Text fontWeight="bold">{vehicleName}</Text>
      <Flex my={-8} mx={-4} alignItems="center">
        {severitySet.icon}
      </Flex>
    </Flex>
  );
};

const PriorityEventCard = ({
  vehicleName,
  eventType,
  eventMessage,
  batteryPercentage,
  onPriorityEventCardClick,
  isUserCommanded,
  id,
  number,
  severity,
}: PriorityEventCardProps) => (
  <MotionBox
    zIndex={1}
    gridArea={`pe-slot-${number}`}
    layout
    key={id}
    flex="1"
    initial={{ x: 0, opacity: 1 }}
    animate={{ x: 0, opacity: 1 }}
    exit={{ x: 0, opacity: 0 }}
    // transition="all 1s cubic-bezier(.08,.52,.52,1)"
    _hover={{
      shadow: 'md',
    }}
    cursor="pointer"
    display="flex"
    overflow="hidden"
    rounded="lg"
    onClick={onPriorityEventCardClick}
    minHeight="15em"
  >
    <Card<BoxProps>
      flex="1"
      heading={
        <PriorityEventCardHeading
          vehicleName={vehicleName}
          severity={severity}
        />
      }
      display="flex"
      flexDirection="column"
    >
      <CardContent height="100%" flexDirection="column" display="flex">
        <Flex>
          <Text>{eventMessage}</Text>
        </Flex>

        <Flex justifyContent="space-between" marginTop="auto">
          <FlightStateTag
            flightStatusText={eventType}
            isUserCommanded={isUserCommanded}
          />
          <Stack spacing={2} isInline align="center" shouldWrapChildren>
            <DynamicBatteryIcon percentage={batteryPercentage} />
            <Text fontFamily="mono">{`${batteryPercentage}%`}</Text>
          </Stack>
        </Flex>
      </CardContent>
    </Card>
  </MotionBox>
);

export const PriorityEventPlaceholder = ({
  number,
}: PriorityEventCardCommonProps) => (
  <MotionFlex
    zIndex={0}
    gridArea={`pe-slot-${number}`}
    flex={1}
    bg="gray.100"
    borderColor="gray.200"
    borderWidth="1px"
    minH="20"
    color="gray.200"
    px="6"
    justifyContent="center"
    alignItems="center"
    fontSize="10rem"
    overflow="hidden"
    rounded="lg"
    userSelect="none"
  >
    {number}
  </MotionFlex>
);

export default PriorityEventCard;
