import React, { useContext } from 'react';
import { ListItem as ChakraListItem, Box } from '@chakra-ui/react';
import ListContext from './ListContext';

type ListItemProps = {
  children: React.ReactNode;
  onClick?: () => void;
};

// eslint-disable-next-line import/prefer-default-export
export const ListItem = ({ children, onClick }: ListItemProps) => {
  const { actionable } = useContext(ListContext);

  return (
    <ChakraListItem
      overflow="hidden"
      borderTopWidth="1px"
      _first={{ borderTopWidth: 0 }}
      onClick={onClick}
    >
      <Box
        as={actionable ? 'button' : 'div'}
        transition="all 0.15s cubic-bezier(.4,0,.2,1)"
        display="flex"
        width="100%"
        /* Unset text-align due to usage of `<button>` tag */
        textAlign="unset"
        _hover={actionable ? { backgroundColor: 'gray.50' } : {}}
        _focus={
          actionable
            ? {
                outline: 'none',
                backgroundColor: 'gray.50',
              }
            : {}
        }
      >
        <Box px="4" py="4" flex="1" justifyContent="flex-start">
          {children}
        </Box>
      </Box>
    </ChakraListItem>
  );
};
